import type {UseFetchOptions} from "#app";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAuthFetch<T>(url: string, options: UseFetchOptions<any>) {
    const route = useRoute()
    const isTablet = route.path.startsWith('/tablet')

    let cookieKey = 'token'
    if (isTablet) {
        cookieKey = 'tablet_token'
    }

    const config = useRuntimeConfig()

    options.baseURL = config.public.baseUrl + '/api/'

    const token = useCookie(cookieKey).value
    if (token !== undefined && token) {
        // Only when there is an impersonate token we use that.
        const impToken = isTablet ? null : useCookie('imp_token').value

        options.headers = {
            Authorization: `Bearer ${impToken ?? token}`,
            ...options.headers
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return useFetch<T>(url, options as any)
}
